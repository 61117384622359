import React, { useState, useEffect } from 'react';

export function AboutMe() {
    const [about, setAbout] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch("/me");
            const about_me = await response.json();
            // console.log(about_me);
            const about_me_prettier = JSON.stringify(about_me, undefined, 2);
            setAbout(about_me_prettier);
        };
        fetchData();
    }, []);

    
    return (
        <div>
            <h1>About Myself</h1>
            <pre id="json">{about}</pre>
            <br></br>
            <a href="/">Go to /</a>
        </div>
    );
}