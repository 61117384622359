import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { OrgChart } from './components/OrgChart';
import { AboutMe } from './components/AboutMe';

function Default() {
  return (
    <div>
      <h1>
        Welcome!
      </h1>
      <h2>
        This assignment implements two API Endpoint
      </h2>
      <h3><a href="/chart">Go to /chart</a></h3>
      <h3><a href="/about-me">Go to /about-me</a></h3>
    </div>
  );
}

function App() {
  return (
    <div>
      

      <BrowserRouter>

        <Routes>
          <Route path="/" element={<Default />}></Route>
          <Route path="/chart" element={<OrgChart />}> </Route>
          <Route path="/about-me" element={<AboutMe />}> </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
