import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import ChartContainer from './ChartContainer';

const options = {
    allowHtml: true,
    allowCollapse: true,
    compactRows: true,
    width: 400,
    height: 300,
}

export function OrgChart() {
    const [chart, setChart] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    function transformOrgData(data) {
        // Process the fetched data into chart nodes
        const nodes = [];

        data = JSON.parse(data);
        data = data["organization"];
        nodes.push([
            "organization",
            "",
            "",
        ]);
        data["departments"].forEach((department) => {
            nodes.push([
                "department:"+department["name"],
                "organization",
                "Manager: " + department["managerName"],
            ]);

            department["employees"].forEach((employee) => {
                nodes.push([
                    employee["name"],
                    "department:"+department["name"],
                    `Office: ${employee["office"]}\nSalary: ${employee["salary"]}\nSkills: ${employee["skills"].join(', ')}`,
                ]);
            });
        });

        return nodes;
    }


    useEffect(() => {
        const chart_fetch = async () => {
            const response = await fetch("/organization-chart");
            const json_res = await response.json();
            // console.log(json_res);
            setChart(JSON.stringify(json_res))
            setIsLoading(false);
        };
        chart_fetch();

    }, []);
    return (
        <div>
            <h1>Organization Chart</h1>
            
            {isLoading
                ? <h2>Loading...This might take several seconds...</h2> 
                :   
                <div>
                    <h3>The following graph shows the structure of the organization. 
                        Double click to collapse a department, 
                        and hover to gain more information about a node</h3>
                    <ChartContainer> 
                        <Chart 
                            chartType="OrgChart"
                            data={transformOrgData(chart)}
                            options={options}
                        />
                    </ChartContainer>
                </div>
            }

            <br></br>
            <a href="/">Go to /</a>
            
        </div>
    );
}